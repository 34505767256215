<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>新增问卷调查</span>
            </a-space>
        </div>
        <div class="top-button">
            <a-button type="primary" @click="save">保存</a-button>
            <!-- <a-button style="margin-left: 10px">预览</a-button> -->
            <a-button style="margin-left: 10px" @click="cancel">取消</a-button>
        </div>
        <div class="main-area">
            <div class="type-choose">
                <div class="type-title">题型选择</div>
                <div><a-button class="type-button" @click="addQuestion(1)"><a-icon type="check-circle" />单选题</a-button></div>
                <div><a-button class="type-button" @click="addQuestion(2)"><a-icon type="check-square" />多选题</a-button></div>
                <div><a-button class="type-button" @click="addQuestion(3)"><a-icon type="unordered-list" />下拉单选</a-button></div>
                <div><a-button class="type-button" @click="addQuestion(4)"><a-icon type="check" />判断题</a-button></div>
                <div><a-button class="type-button" @click="addQuestion(5)"><a-icon type="question" />开放题</a-button></div>
            </div>
            <div class="question-area">
                <div class="question-area-top">
                    <a-input v-model="form.title" style="margin: 20px;width: 980px" placeholder="问卷标题(限40字)"></a-input>
                    <a-textarea v-model="form.description" style="margin: 0px 20px 20px 20px;width: 980px;height:88px" placeholder="问卷说明"></a-textarea>
                </div>
                <div class="question-item">
                    <div style="padding: 20px;display: flex">
                        <div style="width: 80%" v-if="questionCard.type == 1 || questionCard.type == 2 || questionCard.type == 3">
                            <a-form-model layout="horizontal" :label-col="labelCol" :wrapper-col="wrapperCol">
                                <a-form-model-item label="题目">
                                    <a-input v-model="questionCard.topic"></a-input>
                                    <a-button @click="addchoice">添加选项</a-button>
                                </a-form-model-item>
                                <a-form-model-item :label="'选项'+(index+1)" v-for="(item,index) in questionChoice" :key="index">
                                    <div style="display: flex">
                                        <a-input v-model="item.answer"></a-input>
                                        <a-button @click="deleteChoice(index)" shape="circle" icon="close" />
                                    </div>
                                </a-form-model-item>
                            </a-form-model>
                            <div>
                                <a-button type="primary" style="margin-left: 20px" @click="questionComfirm(questionCard.type)">{{isEdit==true?'确认编辑':'确认'}}</a-button>
                                <a-button style="margin-left: 20px" @click="questionCancel">取消</a-button>
                            </div>
                        </div>
                        <div style="width: 80%" v-else-if="questionCard.type == 4 || questionCard.type == 5">
                            <a-form-model layout="horizontal" :label-col="labelCol" :wrapper-col="wrapperCol">
                                <a-form-model-item label="题目">
                                    <a-input v-model="questionCard.topic"></a-input>
                                </a-form-model-item>
                            </a-form-model>
                            <div>
                                <a-button type="primary" style="margin-left: 20px" @click="questionComfirm(questionCard.type)">{{isEdit==true?'确认编辑':'确认'}}</a-button>
                                <a-button style="margin-left: 20px" @click="questionCancel">取消</a-button>
                            </div>
                        </div>
                        <div style="padding: 20px" v-else>
                            请点击左侧按钮添加题目
                        </div>
                        <div style="width: 20%" v-if="questionCard.type == 1 || questionCard.type == 2 || questionCard.type == 3 || questionCard.type == 4|| questionCard.type == 5">
                            <a-form-model layout="horizontal" :label-col="labelCol" :wrapper-col="wrapperCol">
                                <a-form-model-item>
                                    <a-select v-model="questionCard.type">
                                        <a-select-option :value="1">单选题</a-select-option>
                                        <a-select-option :value="2">多选题</a-select-option>
                                        <a-select-option :value="3">下拉单选</a-select-option>
                                        <a-select-option :value="4">判断题</a-select-option>
                                        <a-select-option :value="5">开放题</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-form-model>
                        </div>
                    </div>
                </div>
                <div class="question-area-top" style="margin-top: 20px" v-for="(item,index) in form.questionnaireInsertTopicDTOList" :key="index">
                    <div class="question-list">
                        <h4>{{index+1}}.{{item.topic}}</h4><a-tag>{{item.type==1?'单选题':item.type==2?'多选题':item.type==3?'下拉单选':item.type==4?'判断题':'开放题'}}</a-tag>
                    </div>
                    <div style="margin-left: 40px" v-if="item.type==1 || item.type==3">
                        <a-radio disabled v-for="(it,id) in item.questionnaireInsertTopicChoiceDTOList" :key="id">{{it.answer}}</a-radio>
                    </div>
                    <div style="margin-left: 40px" v-if="item.type==2">
                        <a-checkbox disabled v-for="(it,id) in item.questionnaireInsertTopicChoiceDTOList" :key="id">{{it.answer}}</a-checkbox>
                    </div>
                    <div style="padding: 20px">
                        <a-button type="primary" style="margin-left: 20px" @click="editQuestionItem(item,index)">编辑</a-button>
                        <a-button style="margin-left: 20px" @click="deleteQuestionItem(index)">删除</a-button>
                    </div>
                </div>
            </div>
            <div class="question-setting">
                <div style="padding: 20px">
                    <a-form-model>
                        <a-form-model-item label="封面图片设置">
                            <commonUpload :fileList="fileList" @handleChange="handleChange"></commonUpload>
                        </a-form-model-item>
                        <a-form-model-item label="开始日期">
                            <a-date-picker v-model="form.beginDate" value-format="YYYY-MM-DD HH:mm:ss"></a-date-picker>
                        </a-form-model-item>
                        <a-form-model-item label="截止日期">
                            <a-date-picker v-model="form.endDate" value-format="YYYY-MM-DD HH:mm:ss"></a-date-picker>
                        </a-form-model-item>
                        <a-form-model-item label="问卷对象">
                            <a-select v-model="form.answerType">
                                <a-select-option :value="1">无限制</a-select-option>
                                <a-select-option :value="2">业主</a-select-option>
                                <a-select-option :value="3">业主亲属</a-select-option>
                                <a-select-option :value="4">租户</a-select-option>
                                <a-select-option :value="5">租户亲属</a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item label="是否立即发布">
                            <a-select v-model="form.isRelease">
                                <a-select-option :value="1">发布</a-select-option>
                                <a-select-option :value="0">不发布</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-form-model>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {addQuestion} from "@/api/operation/scroll"
export default {
    name: "addScroll",
    data() {
        return {
            labelCol: { span: 2 },
            wrapperCol: { span: 14 },
            letters: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
            form: {
                title: '',
                description: '',
                answerType: undefined,
                beginDate: '',
                endDate: '',
                questionnaireInsertTopicDTOList: [],
                isRelease: 0,
                imgUrls: [],
            },
            id: undefined,
            questionCard: {
                type: undefined,
                topic: '',
            },
            questionChoice: [],
            fileList: [],
            //编辑问题
            isEdit: false,
            activeIndex: undefined,
        }
    },
    methods: {
        //添加问题
        addQuestion(type) {
            this.isEdit = false;
            this.activeIndex = undefined;
            let obj = {
                type: type,
                topic: '',
                questionnaireInsertTopicChoiceDTOList: [],
            };
            this.questionCard = obj
        },
        //确认,取消添加问题
        questionComfirm(type) {
            if(this.isEdit == false) {
                if(type == 1 || type == 2 || type == 3) {
                    for(let i = 0;i<this.questionChoice.length;i++) {
                        this.questionChoice[i].options = this.letters[i]
                    };
                    let obj = {
                        type: this.questionCard.type,
                        topic: this.questionCard.topic,
                        questionnaireInsertTopicChoiceDTOList: this.questionChoice
                    };
                    this.form.questionnaireInsertTopicDTOList.push(obj)
                } else {
                    let obj = {
                        type: this.questionCard.type,
                        topic: this.questionCard.topic,
                        questionnaireInsertTopicChoiceDTOList: []
                    };
                    this.form.questionnaireInsertTopicDTOList.push(obj)
                }
            } else if (this.isEdit == true) {
                if(type == 1 || type == 2 || type == 3) {
                    for(let i = 0;i<this.questionChoice.length;i++) {
                        this.questionChoice[i].options = this.letters[i]
                    };
                    let obj = {
                        type: this.questionCard.type,
                        topic: this.questionCard.topic,
                        questionnaireInsertTopicChoiceDTOList: this.questionChoice
                    };
                    this.form.questionnaireInsertTopicDTOList[this.activeIndex] = obj
                } else {
                    let obj = {
                        type: this.questionCard.type,
                        topic: this.questionCard.topic,
                        questionnaireInsertTopicChoiceDTOList: []
                    };
                    this.form.questionnaireInsertTopicDTOList[this.activeIndex] = obj
                }
            }
            this.questionCancel()
        },
        questionCancel() {
            this.isEdit = false;
            this.activeIndex = undefined;
            this.questionChoice = [];
            this.questionCard = {
                type: undefined,
                topic: '',
            }
        },
        //添加选项
        addchoice() {
            let obj = {
                answer: '',
                options: '',
            };
            this.questionChoice.push(obj)
        },
        //删除选项
        deleteChoice(index) {
            this.questionChoice.splice(index,1)
        },
        //编辑已经添加的问题
        editQuestionItem(item,index) {
            this.isEdit = true;
            this.activeIndex = index;
            this.questionCard.type = item.type;
            this.questionCard.topic = item.topic;
            if(item.questionnaireInsertTopicChoiceDTOList) {
                this.questionChoice = item.questionnaireInsertTopicChoiceDTOList
            }
        },
        //删除已经添加的问题
        deleteQuestionItem(index) {
            this.form.questionnaireInsertTopicDTOList.splice(index,1)
        },
        //保存
        save() {
            addQuestion(this.form).then(res => {
                if (res.code === 200) {
                    this.$message.success(res.msg);
                    this.$router.go(-1);
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //取消
        cancel() {
            this.questionList = [];
            this.$router.go(-1);
        },
        //图片上传
		handleChange(data) {
			this.fileList = data;
			this.form.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.imgUrls.push(ele.response.data)
				}
			})
		},
    }
}
</script>

<style lang="less">
.top-button {
    padding: 8px 0px 20px 10px
}
.main-area {
    display: flex;
    padding: 0px 20px 20px 10px;
    .type-choose {
        height: 837px;
        width: 220px;
        border: 2px solid #E7E7E7;
        .type-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding: 18px 18px 18px 18px
        }
        .type-button {
            height: 40px;
            width: 180px;
            margin-left: 18px;
            margin-bottom: 20px;
        }
    }
    .question-area {
        margin-left: 20px;
        .question-area-top {
            // height: 184px;
            width: 1020px;
            border: 2px solid #E7E7E7
        }
        .question-item {
            width: 1020px;
            border: 2px solid #E7E7E7;
            margin-top: 20px;
            background: #F5F5F5;
        }
    }
    .question-setting {
        height: 837px;
        width: 230px;
        margin-left: 20px;
        border: 2px solid #E7E7E7;
    }
}
.question-list {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 40px
}
</style>