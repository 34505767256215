import httpService from "@/request"

// 查询所有问卷调查信息
export function getQuestionList(params) {
	return httpService({
		url: `/user/questionnaire/list`,
		method: 'get',
		params: params,
	})
}

// 添加问卷调查表信息
export function addQuestion(params) {
	return httpService({
		url: `/user/questionnaire/insert`,
		method: 'post',
		data: params,
	})
}

// 更新问卷调查表信息
export function updateQuestion(params) {
	return httpService({
		url: `/user/questionnaire/update`,
		method: 'post',
		data: params,
	})
}

// 删除问卷调查信息
export function delQuestion(params) {
	return httpService({
		url: `/user/questionnaire/delete`,
		method: 'post',
		data: params,
	})
}

// 发布下架问卷调查
export function releaseQuestion(params) {
	return httpService({
		url: `/user/questionnaire/isRelease`,
		method: 'get',
		params: params,
	})
}

// 根据问卷调查主键ID查询问卷调查信息
export function findQuestion(params) {
	return httpService({
		url: `/user/questionnaire/findById`,
		method: 'get',
		params: params,
	})
}

// 根据问卷调查主键id查询报表分析信息
export function getReport(params) {
	return httpService({
		url: `/user/questionnaire/reportAnalysis`,
		method: 'get',
		params: params,
	})
}

// 根据题目主键id查询开放题内容信息列表
export function getAnswerList(params) {
	return httpService({
		url: `/user/questionnaire/shortAnswerList`,
		method: 'get',
		params: params,
	})
}